import React from "react"
import parse from "html-react-parser"
import Layout from "@components/layout"

// 言語用設定用ファイル
import langEn from "@content/locales/en/glossary-post.json";
import langJa from "@content/locales/ja/glossary-post.json";

// 共通Link用ファイル
import { LinkSetting } from "@components/link-setting.js";
import { SeoContents } from "@components/seo-data.js" // SEO,meta情報設定

import ogpimage from "@images/ogp.png"

const GlossaryPost = (props) => {

  // 取得データ
  const postData = props.pageContext.data;
  
  // 言語設定
  const setLang = props.pageContext.lang;
  const postLang = setLang === 'en'? langEn: langJa;

  // 言語別URL先頭設定
  let langUrl;
  (setLang === 'en') ? langUrl = '/' : langUrl = '/' + setLang + '/';

  // 構造化データ用配列
  const collection = {image: process.env.ORIGINURL + ogpimage};
  // 作成・更新日
  const date = {
    published: props.pageContext.date, 
    modified: props.pageContext.modified
  };

  // Seo情報設定
  const pathName = `glossary/${props.pageContext.parentSlug}`; // ファイル名
  const title = `${postData.termtitle} - ${postLang["page-title"]}` ;
  const description = `「${postData.termtitle}」${postLang["page-description"]}` ;
  const keywords = postLang["page-keywords"];
  const seoTag = SeoContents( pathName, setLang, title, description, keywords, date, "glossary", postData.termslug, '', collection);

  // リンク設定
  const signPath1 = LinkSetting( setLang ).Register;

  return (
  <>
    {seoTag}
    <Layout>
      <div className="glossary-post" langType={setLang}>
        <div className="FV pt-5 pb-8 bg-fixed">
          <div className="max-w-xl mx-auto px-6 md:max-w-5xl md:pt-5 pt-2">
            <div className=" text-left h1_filed">
              <h1 className="md:text-[60px] text-[35px] font-semibold">{postLang["glossary-h1"]}</h1>
            </div>
          </div>
        </div>

        <div className="max-w-xl mx-auto md:pt-0 pt-6 md:max-w-5xl px-6">
          <div className="text-left md:mt-11 mt-5 h-1/2 max-w-xl mb-0 md:flex">
            <h2 className="tracking-wide text-[26px] font-semibold md:text-[40px] md:mb-10" itemProp="headline">{postData.termtitle}</h2>
            <p className={`${postData.termfurigana === '' || postData.termfurigana === ' '? "hidden": ""}`} style={{ fontSize: 24, paddingTop: 10, paddingLeft: 18}}>{`(${postData.termfurigana})`}</p>
          </div>
        </div>

        <div className="md:px-0 px-6">
          <div className=" md:my-0 md:mx-auto" style={{ paddingTop: 24, paddingBottom: 24, maxWidth: 976}}>
            <p>{parse(postData.termtext)}</p>
          </div>
        </div>

        <div className="flex justify-center" style={{ marginTop: 30 }}>
        <button className="rounded-md py-4 text-white text-lg font-bold bg-black-700 hover:bg-red-500" type="button">
          <a href={`${langUrl}glossary/`} className="test2" style={{ paddingTop: 18, paddingBottom: 18, paddingLeft: 100, paddingRight: 100}}>{postLang['glossary-h2-txt']}</a>
        </button>
        </div>
        
      </div>

      {/* 口座開設ボタン */}
      <div className="Feature bg-fixed">
        <div className="mt-11 py-14 py-14 md:py-10 md:py-10"> 
          <div className="md:mx-auto max-w-5xl flex justify-center">
            <a href={signPath1} className="rounded block md:max-w-xs mx-5 text-center text-white font-bold px-16 my-2 bg-black hover:scale-[1.05] pt-[18px] pb-[20px] duration-300 ">{postLang["open"]}</a>
          </div>
        </div>
      </div>
    </Layout>
  </>
  )
}
export default GlossaryPost